import React, { useState } from "react"
import FsLightbox from "fslightbox-react"
import Img from "gatsby-image"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default props => {
  const [lb, setLb] = useState({
    toggler: false,
    slide: 1,
  })

  function openLightboxOnSlide(number) {
    setLb({
      toggler: !lb.toggler,
      slide: number,
    })
  }

  return (
    <div className="container space-2">
      {props.data.title && (
        <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
          <h2>{props.data.title}</h2>
        </div>
      )}

      <FsLightbox
        toggler={lb.toggler}
        slide={lb.slide}
        sources={[props.data.video]}
      />

      <div className="row justify-content-lg-between">
        <div className="col-lg-12 mb-5 mb-md-0">
          {props.data.message && (
            <span
              class="d-block large font-weight-bold text-cap mb-2 text-center"
              style={{ fontSize: "130%" }}
            >
              Pilot in Post-Production
            </span>
          )}
          {!props.data.message && (
            <div
              className="position-relative max-w-80rem mx-auto video"
              role="button"
              onClick={() => openLightboxOnSlide(1)}
              onKeyDown={() => openLightboxOnSlide(1)}
              tabIndex={0}
            >
              <Img
                className="img-fluid"
                fluid={props.data.image}
                alt="Instagram Post"
              />
              <FontAwesomeIcon
                icon={["far", "play-circle"]}
                className="text-white"
                style={{
                  fontSize: "6rem",
                  position: "absolute",
                  top: "40%",
                  left: "46%",
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
