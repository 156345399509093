import React from "react"
import Layout from "../components/layout/layout"
import Hero from "../components/hero"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import VideoBlock from "../components/VideoBlock"
import PdfBlock from "../components/PdfBlock"

export default data => {
  const video1 = {
    video: data.data.sanityPages._rawContent[0].video1_url,
    title: data.data.sanityPages._rawContent[0].video1_title,
    image: data.data.sanityPages.content[0].video1_image.asset.fluid,
  }
  const video2 = {
    video: data.data.sanityPages._rawContent[0].video2_url,
    title: data.data.sanityPages._rawContent[0].video2_title,
    image: data.data.sanityPages.content[0].video2_image.asset.fluid,
  }
  const video3 = {
    video: data.data.sanityPages._rawContent[0].video3_url,
    title: data.data.sanityPages._rawContent[0].video3_title,
    image: data.data.sanityPages.content[0].video2_image.asset.fluid,
    message: "Pilot in Post-Production.",
  }
  const pdf = {
    title: data.data.sanityPages._rawContent[0].pitch_deck_title,
    pdf_url: data.data.sanityPages._rawContent[0].pitch_deck.asset.url,
  }

  return (
    <Layout>
      <Helmet title={`Rajiv Satyal - ${data.data.sanityPages.title}`} />
      <Hero data={data.data.sanityPages} />
      <VideoBlock data={video1} />
      <VideoBlock data={video2} />
      <PdfBlock data={pdf} />
      <VideoBlock data={video3} />
      <div className="container space-2">
        <h2 style={{ textAlign: "center" }}>Thank You!</h2>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query MITMQuery {
    sanityPages(slug: { current: { eq: "mitm" } }) {
      id
      hero_subtitle
      hero_title
      hero_video
      title
      _rawContent(resolveReferences: { maxDepth: 10 })
      content {
        ... on SanityMitm {
          video1_image {
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
                src
              }
            }
          }
          video2_image {
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
                src
              }
            }
          }
          video3_image {
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
                src
              }
            }
          }
        }
      }
      hero_bg {
        hotspot {
          x
          y
        }

        asset {
          fluid(maxWidth: 1920) {
            ...GatsbySanityImageFluid
            src
          }
        }
      }
    }
  }
`
