import React from "react"

export default props => {
  const pdf_url = `https://docs.google.com/viewer?url=${props.data.pdf_url}&embedded=true`
  return (
    <div className="container space-2">
      {props.data.title && (
        <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
          <h2>{props.data.title}</h2>
        </div>
      )}
      <div className="col-lg-12 mb-5 mb-md-0">
        <div
          className="position-relative max-w-80rem mx-auto"
          style={{ height: "50vh" }}
        >
          <iframe width="100%" height="100%" src={pdf_url} title="pdf"></iframe>
        </div>
      </div>
    </div>
  )
}
